import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Particles from "../components/particle/particle"
import Contact from "../components/contact/contact"

class BlogIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Contact />
        <SEO />
        <Particles />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
