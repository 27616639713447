import React from "react"
import styled from "styled-components"
import VerticalLine from "../shapes/vertical-line"

const SectionRightTitle = styled.h3`
  font-family: "Fira Sans";
  font-weight: 900;
  padding-left: 0.3em;
  letter-spacing: 0.01em;
  text-align: left;
  color: #3e4346;
  margin-left: 30px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 25.5px;
  background: linear-gradient(to right, #e4edf2 0%, #fff 100%);
  margin-top: 0px;
`

const SectionRightDescription = styled.p`
  font-family: "Fira Sans";
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 25px;
  text-align: left;
  color: #3e4346;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  @media screen and (min-width: 690px) {
    width: 403px;
  }
`

const SectionRightCta = styled.div`
  font-family: Roboto;
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.05em;
  line-height: 20px;
  text-align: left;
  color: #3e4346;
  opacity: 0.9;
  margin-top: 20px;
`

const SectionRightContainer = styled.div`
  position: relative;
  width: 100%;
`

const SectionTitleDescriptionCta = props => {
  return (
    <SectionRightContainer {...props}>
      <VerticalLine style={{ position: "absolute", top: 0, left: 0 }} />
      <SectionRightTitle>{props.title}</SectionRightTitle>
      <SectionRightDescription>{props.description}</SectionRightDescription>
      <SectionRightCta>{props.cta}</SectionRightCta>
    </SectionRightContainer>
  )
}

export default SectionTitleDescriptionCta
