import styled from "styled-components"
import BackgroundImageDesktop from "../../../static/contact/background_contact.svg"

const ContactOptionsContainer = styled.div`
  display: flex;
  margin-top: 60px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${BackgroundImageDesktop});
  position: relative;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    margin-top: 100px;
  }
`

const CenterContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const ContactOptionsContentContainer = styled.div`
  background-color: white;
  max-width: 600px;
`

const CompanyInformationContainer = styled.div`
margin-top: 60px;
border: 1px solid #cbd6e2;
border-radius: .1875rem;
display:inline-block
justify-content: center;
@media (min-width: 1000px) {
    margin: auto;
}
max-width: 1024px;
`

const ContentItemContainer = styled.div`
  padding: 10px;
  margin: 10px;
  display: block;
  color: #3e4346;
  font-size: 0.8em;
`

const ContentItemHeaderContainer = styled.div`
  font-size: 16px;
`

const AnchorButton = styled.div`
  font-size: 40px;
  position: absolute;
  bottom: 5%;
  left: 50%;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;

  @media (max-width: 1920px) {
    bottom: 1%;
  }

  @-webkit-keyframes sdb04 {
    0% {
      -webkit-transform: translate(0, 0);
    }
    20% {
      -webkit-transform: translate(0, 10px);
    }
    40% {
      -webkit-transform: translate(0, 0);
    }
  }
  @keyframes sdb04 {
    0% {
      transform: translate(0, 0);
    }
    20% {
      transform: translate(0, 10px);
    }
    40% {
      transform: translate(0, 0);
    }
  }
`

export {
  ContactOptionsContainer,
  ContactOptionsContentContainer,
  ContentItemContainer,
  CompanyInformationContainer,
  CenterContainer,
  AnchorButton,
  ContentItemHeaderContainer,
}
