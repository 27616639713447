import styled from "styled-components"

const VerticalLine = styled.div`
  width: 0px;
  height: 20px;
  background: transparent;
  border: 4px solid #454d62;
`

export default VerticalLine
